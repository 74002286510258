
<template>
  <div class="login">
    <el-form
      ref="loginForm"
      :model="loginForm"
      :rules="loginRules"
      label-position="left"
      label-width="0px"
      class="login-form"
    >
      <h3 class="title">登录</h3>
      <div class="switchLang">
        <switchLanguages></switchLanguages>
      </div>
      <el-form-item prop="username">
        <el-input v-model="loginForm.username" type="text" auto-complete="off" placeholder="账号">
          <svg-icon
            slot="prefix"
            icon-class="user"
            class="el-input__icon"
            style="height: 39px;width: 13px;margin-left: 2px;"
          />
        </el-input>
      </el-form-item>
      <p class="login-tip" v-show="bigChar">大写锁定已打开</p>
      <el-form-item prop="password">
        <el-input
          v-model="loginForm.password"
          type="password"
          auto-complete="off"
          placeholder="密码"
          show-password
          @keyup.native="loginKeyDown"
          @keyup.enter.native="handleLogin"
        >
          <svg-icon
            slot="prefix"
            icon-class="password"
            class="el-input__icon"
            style="height: 39px;width: 13px;margin-left: 2px;"
          />
        </el-input>
      </el-form-item>
      <el-form-item prop="googlekey">
        <el-input
          v-model="loginForm.googlekey"
          type="text"
          auto-complete="off"
          placeholder="动态密码"
          @keyup.enter.native="handleLogin"
        >
          <svg-icon
            slot="prefix"
            icon-class="key"
            class="el-input__icon"
            style="height: 39px;width: 13px;margin-left: 2px;"
          />
        </el-input>
      </el-form-item>
      <!-- <el-checkbox v-model="loginForm.rememberMe" style="margin:0px 0px 25px 0px;">记住密码</el-checkbox> -->
      <el-form-item style="width:100%;">
        <el-button
          :loading="loading"
          size="medium"
          type="primary"
          style="width:100%;"
          @click.native.prevent="handleLogin"
        >
          <span v-if="!loading">登 录</span>
          <span v-else>登 录 中...</span>
        </el-button>
      </el-form-item>
      <el-form-item
        class="pass-form-item"
        style="width:100%;"

      >
        <span class="" @click="resetPassHandle">重置密码</span>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
  // import { md5 } from '@/utils/md5'
  import md5 from "js-md5";
  import { forgetPwd } from '../../api/system/user'

  export default {
    name: "Login",
    data() {
      return {
        md5Pwd: "",
        firstTochar: false,
        bigChar: false,
        loginForm: {
          username: "",
          password: "",
          googlekey: ""
        },
        loginRules: {
          username: [
            { required: true, trigger: "blur", message: "用户名不能为空" }
          ],
          password: [
            { required: true, trigger: "blur", message: "密码不能为空" }
          ],
          googlekey: [
            { required: true, trigger: "blur", message: "动态密码不能为空" }
          ]
        },
        loading: false,
        redirect: undefined
      };
    },
    watch: {
      $route: {
        handler: function(route) {
          this.redirect = route.query && route.query.redirect;
        },
        immediate: true
      }
    },
    methods: {
      resetPassHandle(){
        if(!this.loginForm.username){
          return this.$message({
            message: '请填写账号',
            type: 'warning'
          })
        }
        let domainName = window.location.host
        forgetPwd({username: this.loginForm.username,domainName:'https://'+domainName}).then((res) => {
          this.$message({
            message: '重置密码链接已发送到您的注册邮箱！',
            type: 'success'
          })
        });
      },
      handleLogin() {
        this.$refs.loginForm.validate(valid => {
          let pass = this.loginForm.password;
          if (pass !== this.md5Pwd) {
            pass = md5(pass);
          }
          const user = {
            username: this.loginForm.username,
            password: pass,
            googlekey: this.loginForm.googlekey
          };
          if (valid) {
            this.loading = true;
            this.$store
              .dispatch("Login", user)
              .then(() => {
                this.loading = false;
                this.$router.push({ path: this.redirect || "/" });
              })
              .catch(() => {
                this.loading = false;
              });
          } else {
            console.log("error submit!!");
            return false;
          }
        });
      },
      loginKeyDown(event) {
        const _that = this;
        //是否输入过字母键，且判断是否按键为caps lock
        if (_that.firstTochar) {
          if (event.keyCode === 20) {
            _that.bigChar = !_that.bigChar;
            return;
          }
        }
        //未输入过字母键，或按键不是caps lock，判断每次最后输入的字符的大小写
        var e = event || window.event;
        var keyvalue = e.keyCode ? e.keyCode : e.which;
        var shifKey = e.shiftKey ? e.shiftKey : keyvalue == 16 ? true : false;
        if (typeof _that.loginForm.password === "undefined") {
          return;
        }
        var strlen = _that.loginForm.password.length;
        var password = _that.loginForm.password;

        if (strlen) {
          var uniCode = password.charCodeAt(strlen - 1);
          if (keyvalue >= 65 && keyvalue <= 90) {
            //如果是字母键
            _that.firstTochar = true;
            if (
              (uniCode >= 65 && uniCode <= 90 && !shifKey) ||
              (uniCode >= 97 && uniCode <= 122 && shifKey)
            ) {
              _that.bigChar = true;
            } else {
              _that.bigChar = false;
            }
          }
        }
      }
    }
  };
</script>

<style rel="stylesheet/less" lang="less" scoped>
.login {
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url("../../assets/img/bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
}
.switchLang{
  position: absolute;
  top: 10px;
  right: 10px;
}
.title {
  margin: 0px auto 40px auto;
  text-align: center;
  color: #707070;
}

.login-form {
  border-radius: 6px;
  background: #ffffff;
  width: 365px;
  padding: 50px 25px 5px 25px;
  position: relative;
  .el-input {
    height: 38px;

    input {
      height: 38px;
    }
  }
}

.login-tip {
  font-size: 12px;
  text-align: center;
  color: red;
}

.pass-form-item {
  text-align: right;
  font-size: 14px;
  color: #999999;
  cursor: pointer;
}
</style>
