import { render, staticRenderFns } from "./oldLogin.vue?vue&type=template&id=02bc1020&scoped=true&"
import script from "./oldLogin.vue?vue&type=script&lang=js&"
export * from "./oldLogin.vue?vue&type=script&lang=js&"
import style0 from "./oldLogin.vue?vue&type=style&index=0&id=02bc1020&rel=stylesheet%2Fless&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "02bc1020",
  null
  
)

export default component.exports